import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SubcontractorModel } from '../../_models/subcontractors.model';
import {
  SubcontractorLiteSearchResult
} from '../../_models/subcontractors.model';
import { environment } from '../../../environments/environment';
import { CompanyHistoricsProjects } from '../../_models/company.model';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  private apiUrl = environment.urlApi;

  constructor(private http: HttpClient) {}

  // Method to get the list of subcontractors
  getSubcontractorsList(companyId: string, searchText: string, page: number, pageSize: number, categories: string[], projects: number[], regions: string[]): Observable<SubcontractorLiteSearchResult> {

    page = page + 1;
    let params = new HttpParams()
    .set('searchText', searchText)
    .set('page', page.toString())
    .set('pageSize', pageSize.toString());

    if (categories.length === 1 && categories[0] === '') {
        categories = [];
    }
    
    categories.forEach((category) => {
        params = params.append('categories', category);
    });

    projects.forEach((project) => {
        params = params.append('projects', project);
    });

    regions.forEach((region) => {
        params = params.append('coverage', region);
    });

    return this.http.get<SubcontractorLiteSearchResult>(`${this.apiUrl}/Registers/${companyId}/subcontractors`, { params })
      .pipe(
        map(data => data),
        catchError(this.handleError)
      );
}

  getSubcontractorDetail(companyId: string, subcontractorId: string): Observable<SubcontractorModel> {
    return this.http.get<SubcontractorModel>(`${this.apiUrl}/Registers/${companyId}/subcontractors/${subcontractorId}`)
      .pipe(
        map(data => data as SubcontractorModel),
        catchError(this.handleError)
      );
  }

  getHistoricProjects(companyId: string, subcontractorId: string, deadline: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/Registers/${companyId}/historicProjects/${subcontractorId}/${deadline}`)
      .pipe(
        map(data => data),
        catchError(this.handleError)
      );
  }

  getHistoricProjectsByExternalReference(companyId: string, origin: string, external_id: string, deadline: string): Observable<CompanyHistoricsProjects[]> {
    return this.http.get(`${this.apiUrl}/Registers/${companyId}/historicProjects/${origin}/${external_id}/${deadline}`)
      .pipe(
        map(data => data as CompanyHistoricsProjects[]),
        //catchError(this.handleError)
      );
  }

  
  private handleError(error: any): Observable<never> {
    console.error('HTTP request error:', error);
    return throwError(
      () => new Error('An error occurred, please try again later.')
    );
  }
}
