import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from '../../../_models/category.model';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private apiUrl = environment.urlApi;

  constructor(private http: HttpClient) {}

  getCategoriesList(): Observable<Category[]> { 
    return this.http.get<Category[]>(`${this.apiUrl}/Categories`);
  }

  getCategoriesById(id: string): Observable<Category[]> {
    return this.http.get<Category[]>(`${this.apiUrl}/Categories/companies/${id}`);
  }

  getCategoriesByExternalReference(origin: string, external_id: string): Observable<Category[]> {
    return this.http.get<Category[]>(`${this.apiUrl}/Categories/companies/${origin}/${external_id}`);
  }
}
