import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Project } from '../../_models/project.model';
import { Company, CompanyInformationBase, CompanyInformationComercial } from '../../_models/company.model';
import { Coverage } from '../../_models/coverage.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private apiUrl = environment.urlApi;

  constructor(private http: HttpClient) {}



  getProjectsFilter(companyId: string, name: string, limit: number): Observable<Project[]>  {

    let params = new HttpParams()
    .set('name', name)
    .set('limit', limit.toString());

    return this.http
      .get<Project[]>(`${this.apiUrl}/Companies/${companyId}/projects`, { params })
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getCompanyById(companyId: string): Observable<Company> {
    return this.http
      .get<Company>(`${this.apiUrl}/Companies/${companyId}`)
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getCoverageByCompanyId(companyId: string): Observable<Coverage[]>
  {
    return this.http
    .get<Coverage[]>(`${this.apiUrl}/Companies/${companyId}/coverage`)
    .pipe(
      map((data) => data),
      catchError(this.handleError)
    );
  }

  getProjectsBySubcontractorId(companyId: string, subcontractorId: string): Observable<Project[]> {
    return this.http
      .get<Project[]>(`${this.apiUrl}/Registers/${companyId}/Subcontractors/${subcontractorId}/projects`)
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );
  }

  getEDPRating(companyId: string, subcontractorId: string): Observable<Company> {
    let response = this.http.get<Company>(`${this.apiUrl}/Companies/${companyId}/EDP/${subcontractorId}`)
    return this.http
      .get<Company>(`${this.apiUrl}/Companies/${companyId}/EDP/${subcontractorId}`)
      .pipe(
        map((data) => data),
        catchError(this.handleError)
      );

  }

  getInformationBase(origin: string, externalId: string, identificationNumber: string, companyId: string): Observable<CompanyInformationBase>
  {
    return this.http
    .get<CompanyInformationBase>(`${this.apiUrl}/Companies/${companyId}/informationbase/${origin}/${externalId}/${identificationNumber}`)
    .pipe(
      map((data) => data)
      //,      catchError(this.handleError)
    );
  }

  getInformationComercial(origin: string, externalId: string, companyId: string): Observable<CompanyInformationComercial>
  {
    return this.http
    .get<CompanyInformationComercial>(`${this.apiUrl}/Companies/${companyId}/informationcomercial/${origin}/${externalId}`)
    .pipe(
      map((data) => data)
      //,      catchError(this.handleError)
    );
  }

  private handleError(error: any): Observable<never> {
    console.error('HTTP request error:', error);
    return throwError(
      () => new Error('An error occurred, please try again later.')
    );
  }

}
